.contact {
    width: 100%;
}

.contact__hero {
    width: 100%;
    height: 30vh;
    background: url("../../assets/imgs/conatctHero.jpg");
    background-position: 0 70%;
    background-size: cover;

}

.contact__titleCont {
    width: 100%;
    height: 100%;
    background: #d8b5a433;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact__title {
    /* margin: 100px auto; */
    font-size: 3rem;
    text-shadow: 1px 1px 1px #000;
    text-align: center;
    color: #F46D3A;
}

.contact__container {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    margin: 80px auto;
    justify-content: center;
    align-items: center;
}

.contact__form {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border-radius: 20px;
}

.form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.form .form__inputText,
.form textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #F46D3A;
}

.form textarea {
    padding: 10px;
}

.form input {
    height: 30px;
    padding-left: 10px;

}

.form button {
    padding: 10px 30px;
    border-radius: 10px;
    background: #3C334F;
    color: #fff;
    font-size: 1.2rem;
}

.form__terms {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.terms__check {
    width: 30px;
}

.terms__text {
    font-size: 0.8rem;
}

.terms__link {
    color: #F46D3A;
}

.terms__link:hover {
    color: #B6374A;
}

.contact__right {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.contact__btns {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.btns__btn {
    width: 300px;
    height: 140px;
    background: #D8B5A4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 20px;
    border-radius: 30px;
    color: #fff;
}

.btn__icon {
    width: 70px;
    height: 70px;
    background-color: #3C334F;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 5px;
}

.btn__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact__socials {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.socials__item {
    width: 60px;
    height: 60px;
    background: #3C334F;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    cursor: pointer;
    color: #fff;
}

.none {
    display: none;
}

.successMessage {
    color: #F46D3A;
}