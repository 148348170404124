.hero {
    width: 100vw;
    height: 100vh;
    background: url("../../assets/imgs/audio.jpg");
    background-position: center;
    /* background-attachment: fixed; */
    background-size: cover;
    position: relative;
}

.hero__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero__slogan {
    color: #fff;
    font-size: 4rem;
    text-align: center;
    text-shadow: 1px 1px 1px #000;
}

.sesgoDown {
    width: 100%;
    position: absolute;
    bottom: 0;
}

@media screen and (min-width: 768px) {
    .hero__slogan {
        font-size: 6rem;
    }
}