.tecnic {
    width: 95%;
    margin: auto;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-top: 100px;
}

.tecnic__images {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
    background: #D8B5A4;
    padding: 15px;
    border-top-left-radius: 300px;
    border-bottom-right-radius: 300px;
    box-shadow: -1px 4px 9px #000;
    ;
}

.tecnic__image {
    width: 30vw;
    max-width: 450px;
    border-radius: 5px;
}

.image1 {
    border-top-left-radius: 300px;
}

.image2 {
    border-bottom-right-radius: 300px;
}

.tecnic__text {
    max-width: 800px;
}

.tecnic__figures {
    display: flex;
    margin-bottom: 15px;
    gap: 5px;
    align-items: center;
}

.figures__square {
    width: 20px;
    height: 20px;
    background: #B6374A;
}

.figures__line {
    width: 300px;
    height: 3px;
    border-radius: 2px;
    background: #B6374A;
}

.tecnic__text h3 {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #F46D3A;

}

.tecnic__text p {
    color: #3C334F;
    text-align: justify;
}

@media screen and (min-width: 1280px) {
    .tecnic {
        flex-direction: row;
        justify-content: center;
        gap: 30px;
    }

    .tecnic__images {
        flex-direction: column;
    }
}