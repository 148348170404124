.categorie {
  width: 100%;
}

.categorie__hero {
  width: 100%;
  height: 30vh;
}

.categorie__heroFront {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d8b5a433;
}

.categorie__hero h2 {
  font-size: 3rem;
  text-shadow: 1px 1px 1px #000;
  text-align: center;
  color: #f46d3a;
}

.categorie__container {
  width: 100%;
  margin: 100px 0;
}

.categorie__list {
  width: 95%;
  margin: auto;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.cardProduct2 {
  width: 250px;
  border: 1px solid #f46d3a;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}

.cardProduct2:hover {
  border: 1px solid #b6374a;
  transform: scale(1.02);
}

.cardProduct2__img {
  width: 170px;
  height: 170px;
  object-fit: cover;
}

.cardProduct2__figures {
  display: flex;
  align-items: center;
  gap: 5px;
}

.line__left,
.line__right {
  width: 70px;
  height: 2px;
  background: #b6374a;
  border-radius: 5px;
}

.square__center {
  width: 10px;
  height: 10px;
  background: #b6374a;
}

.cardProduct2__name {
  color: #f46d3a;
}

.cardProducct__des {
  color: #3c334f;
}
