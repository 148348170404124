.topProducts {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.topProducts h2 {
    font-size: 2rem;
    color: #F46D3A;
}

.product__container {
    width: 330px;
    position: relative;
    max-width: 1200px;
    overflow: hidden;
    margin: 50px 0;
}

.product__list {
    display: flex;
    gap: 40px;
    overflow: hidden;
    justify-content: flex-start;
    align-items: center;
    transition: all 1s ease;
    margin: 0 40px;
}

.cardProduct {
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.cP__image {
    width: 250px;
}

.cP__text {
    text-align: center;
    width: 250;
}

.cP__text h3 {
    color: #B6374A;
}

.cP__text span {
    color: #3C334F;
}

.arrow {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F46D3A;
    border-radius: 50%;
    color: #fff;
    font-weight: 900;
    font-size: 1.5rem;
    cursor: pointer;
}

.arrow:hover {
    background: #D8B5A4;
}

.arrow_prev {
    position: absolute;
    left: 0;
    top: calc(100% - 50%);
}

.arrow_next {
    position: absolute;
    right: 0;
    top: calc(100% - 50%);
}

@media screen and (min-width: 768px) {
    .product__container {
        width: 620px;
    }
}

@media screen and (min-width: 1080px) {
    .product__container {
        width: 910px;
    }
}

@media screen and (min-width: 1200px) {
    .product__container {
        width: 1200px;
    }
}