.categories {
    width: 100%;
    margin: 30px 0;
}

.categories__container {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: auto;
    max-width: 1200px;
}

.categories__item {
    width: 100%;
    height: 60px;
    background-size: cover;
    border-radius: 5px;
    cursor: pointer;
}


.item__front {
    width: 100%;
    height: 100%;
    background-color: #d8b5a460;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    color: #fff;
    font-size: 1.5rem;
    text-align: center;
    text-shadow: 1px 1px 1px #000;
    transition: all 0.5s ease;
}

.item__front:hover {
    background-color: rgba(0, 0, 0, 0.365);
}

@media screen and (min-width: 768px) {
    .categories__item {
        width: 350px;
        height: 250px;
    }

}