.distributors {
    width: 100%;
}

.distributors__hero {
    width: 100%;
    height: 30vh;
    background: url("../../assets/imgs/disHero.jpg");
    background-position: 0 60%;
    background-size: cover;
}

.distributors__heroFront {
    width: 100%;
    height: 100%;
    background: #d8b5a433;
    display: flex;
    justify-content: center;
    align-items: center;
}

.distributors__text {
    font-size: 3rem;
    text-shadow: 1px 1px 1px #000;
    text-align: center;
    color: #F46D3A;
}

.distributors__container {
    width: 100%;
    margin: 50px 0;
}

.distributors__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.dis__card {
    background: #D8B5A4;
    width: 100%;
    max-width: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0 40px;
    border-radius: 100px;
}

.dis__image {
    width: 150px;
}

.dis__text {
    display: flex;
    flex-direction: column;
}

.dis__text span {
    color: #F46D3A;
    font-weight: bolder;
    font-size: 1.2rem;
}

.dis__text a {
    color: #3C334F;
    transition: all 0.5s ease;
}

.dis__text a:hover {
    color: #B6374A;
}