.header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 90;
}

.header__container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
}


.header__image {
    width: 150px;
    height: 70px;
}

.header__list {
    position: absolute;
    top: 60px;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    transition: all 1s ease;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}

.header__list .list__item {
    padding: 10px;
    color: #fff;
    cursor: pointer;
}

.header__list .list__item:hover {
    border-bottom: solid 1px #F46D3A;
    color: #F46D3A;
}

.header__list .list__item a {
    padding: 10px;
    color: #fff;
    cursor: pointer;
}

.header__list .list__item a:hover {
    color: #F46D3A;
}

.header__dropDown {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header__hamburgerMenu {
    width: 50px;
    height: 50px;
    font-size: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    bottom: 50px;
    right: 20px;
    transition: all 0.5s ease;
    cursor: pointer;
    z-index: 90;
}

.header__hamburgerMenu:hover {
    background: rgba(0, 0, 0, 0.4);
}

.dropDown {
    display: none;
}

.dropDown li a {
    /* display: block; */
    margin: 5px 0;
    cursor: pointer;
    color: #fff;
    transition: all 0.5s ease;
}

.dropDown li:hover a {
    /* border-bottom: solid 1px #F46D3A; */
    color: #F46D3A;
}

.show--menu {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.show {
    display: block;
}

.header__btnLang {
    border: 1px solid #fff;
    padding: 2px 12px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.5s ease;
}

.header__langText {
    color: #fff;
}

.header__btnLang:hover {
    border: 1px solid #F46D3A;
}

.header__btnLang:hover .header__langText {
    color: #F46D3A;
}

@media screen and (min-width: 768px) {
    .header__list {
        position: static;
        clip-path: none;
        flex-direction: row;
        top: 0;
        padding: 0;
        background: transparent;
        width: auto;
    }

    .header__hamburgerMenu {
        display: none;
    }

    .header__dropDown {
        position: relative;
    }

    .dropDown {
        width: 250px;
        position: absolute;
        top: 60px;
        background: rgba(0, 0, 0, 0.3);
        padding: 10px;
    }

    .header__list .list__item {
        padding: 17.5px;
    }

}