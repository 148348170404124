.profesionals {
    width: 95%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.p__image {
    width: 350px;
    box-shadow: -1px 4px 9px #000;
    ;
}

.p__textCont {
    max-width: 800px;
}


.p__textCont h3 {
    color: #F46D3A;
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: right;
}

.prof__figures {
    display: flex;
    margin-bottom: 15px;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;
}

.figures__square {
    width: 20px;
    height: 20px;
    background: #B6374A;
}

.figures__line {
    width: 300px;
    height: 3px;
    border-radius: 2px;
    background: #B6374A;
}

.p__textCont p {
    text-align: justify;
    color: #3C334F;
}

@media screen and (min-width: 768px) {
    .profesionals {
        flex-direction: row-reverse;
    }
}