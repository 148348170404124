.product {
  width: 100%;
  margin: 100px 0 50px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.product__images {
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product__mainBack {
  width: 90%;
  height: auto;
  max-width: 521px;
}

.product__mainFront {
  width: 100%;
  max-width: 521px;
  object-fit: cover;
}

.product__thumbs {
  width: 100%;
  max-width: 700px;
  display: flex;
  justify-content: center;
  padding: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.product__thumb {
  width: 15%;
  border: 1px solid #f46d3a;
  cursor: pointer;
}

.product__text {
  width: 100%;
  margin: 20px 0 0 0;
  max-width: 700px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  color: #3c334f;
}

.product__text h3 {
  color: #f46d3a;
  font-size: 2.5rem;
}

.product__text p {
  text-align: justify;
  color: #3c334f;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
}

.product__features span {
  font-size: 1.5rem;
  margin-bottom: 20px;
  display: block;
  color: #3c334f;
}

.product__features ul {
  padding-left: 20px;
}

.product__features ul li {
  padding-left: 20px;
  list-style: circle;
}

.product__btms {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.product__btm {
  width: 250px;
  background: #b6374a;
  padding: 15px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 15px;
}

.product__btmIcon {
  font-size: 2rem;
}
