.Footer {
    width: 100%;
    background: #D8B5A4;
}

.footer__logo {
    width: 350px;
}

.footer__first {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
}

.footer__links h4 {
    font-size: 1.2rem;
    margin-bottom: 8px;
    color: #F46D3A;
}

.footer__links ul li a {
    color: #3C334F;
    cursor: pointer;
    transition: all 0.5s ease;
}

.footer__links ul li a:hover {
    color: #B6374A;
}

.footer__second {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #3C334F;
    color: #fff;
    padding: 10px;
}


.terms {
    color: #fff;
    padding: 2px 10px;
}

.terms:hover {
    color: #B6374A;
    padding: 2px 10px;
}

.terms-left {
    border-right: 1px solid #fff;
}

.terms-right {
    border-left: 1px solid #fff;
}


@media screen and (min-width: 768px) {
    .footer__second {
        flex-direction: row;
        justify-content: space-around;
    }
}