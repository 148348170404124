@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap");

* {
  border: 0;
  outline: 0;
  margin: 0px;
  padding: 0px;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  /* --primary: #b11919;
  --white: #f4f4f9;
  --transparent-dark: rgb(0, 0, 0, 0.5);
  --white-hover: #bf9d8f;
  --dark-acent: #9a8a7f; */
}

body {
  font-family: "Poppins", sans-serif;
  background: #fff;
}